<template>
  <v-container>
    <user-table :loading="loading" :agent_data="agent_data"></user-table>
  </v-container>
</template>

<script>
import UserTable from "../../components/Admin/UserTable.vue";

import gql from "graphql-tag";

export default {
  name: "AgentTable",
  components: { UserTable },

  data: () => ({
    loading: false,
    agent_data: [],
  }),

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loading = true;
      const { data } = await this.$apollo.query({
        query: gql`
          query AdminUsers {
            users {
              admin
              phone
              name
              hidden
              status
              id
              created_at
            }
          }
        `,
      });

      this.agent_data = data.users;

      this.agent_data.forEach((item) => {
        item.marketer_clean = "Not Implmented";
        item.allowed_carriers_cnt = 0;
      });
      this.loading = false;
    },
  },
};
</script>
